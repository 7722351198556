import styled, { css } from 'styled-components'
import { TooltipBoxProps } from './interface'

const TooltipMessageXsCSS = css`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 200px;
  }
`

export const TooltipMessage = styled.div`
  background: ${({ theme }) => theme.palette.charcoal};
  border-radius: 3px;
  color: #fff;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  line-height: 1.14;
  padding: ${({ theme }) => theme.space.xs};
  text-align: left;
  width: max-content;
  max-width: 277px;

  ${TooltipMessageXsCSS};
`

export const TooltipBubble = styled.div<TooltipBoxProps>`
  min-width: 120px;
  max-width: 277px;
  position: absolute;
  flex: none;
  z-index: 10;
  &::after {
    content: '';
    position: absolute;

    ${({ placement }) =>
      placement === 'top' &&
      css`
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 7px solid ${({ theme }) => theme.palette.charcoal};
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      `}

    ${({ placement }) =>
      placement === 'topLeft' &&
      css`
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 7px solid ${({ theme }) => theme.palette.charcoal};
        bottom: 0;
        left: 4%;
        transform: translateX(-4%);
      `}

      ${({ placement }) =>
      placement === 'topRight' &&
      css`
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 7px solid ${({ theme }) => theme.palette.charcoal};
        bottom: 0;
        right: 5%;
        transform: translateX(4%);
      `}

      ${({ placement }) =>
      placement === 'bottom' &&
      css`
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 7px solid ${({ theme }) => theme.palette.charcoal};
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      `}

      ${({ placement }) =>
      placement === 'bottomLeft' &&
      css`
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 7px solid ${({ theme }) => theme.palette.charcoal};
        top: 0;
        left: 4%;
        transform: translateX(-4%);
      `}

      ${({ placement }) =>
      placement === 'bottomRight' &&
      css`
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 7px solid ${({ theme }) => theme.palette.charcoal};
        top: 0;
        right: 5%;
        transform: translateX(4%);
      `}

      ${({ placement }) =>
      placement === 'left' &&
      css`
        border-color: orange;
        border-left: 7px solid ${({ theme }) => theme.palette.charcoal};
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        top: 50%;
        right: 0%;
        transform: translateY(-50%);
      `}

      ${({ placement }) =>
      placement === 'leftTop' &&
      css`
        border-color: orange;
        border-left: 7px solid ${({ theme }) => theme.palette.charcoal};
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        top: 19%;
        right: 0%;
        transform: translateY(-19%);
      `}

      ${({ placement }) =>
      placement === 'leftBottom' &&
      css`
        border-color: orange;
        border-left: 7px solid ${({ theme }) => theme.palette.charcoal};
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        bottom: 10%;
        right: 0%;
        transform: translateY(-19%);
      `}

      ${({ placement }) =>
      placement === 'right' &&
      css`
        border-right: 7px solid ${({ theme }) => theme.palette.charcoal};
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      `}

      ${({ placement }) =>
      placement === 'rightTop' &&
      css`
        border-right: 7px solid ${({ theme }) => theme.palette.charcoal};
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        bottom: 46%;
        left: 0;
        transform: translateY(-46%);
      `}

      ${({ placement }) =>
      placement === 'rightBottom' &&
      css`
        border-right: 7px solid ${({ theme }) => theme.palette.charcoal};
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        bottom: 0%;
        left: 0;
        transform: translateY(-50%);
      `}
  }

  @element body and (min-height: 101vh) {
    border: 4px solid red;
  }

  ${({ placement }) =>
    placement === 'top' &&
    css`
      bottom: 100%;
      left: 50%;
      padding-bottom: 6px;
      transform: translateX(-50%);
    `}

  ${({ placement }) =>
    placement === 'topLeft' &&
    css`
      bottom: 100%;
      left: 4%;
      padding-bottom: 7px;
      transform: translateX(-4%);
    `}

  ${({ placement }) =>
    placement === 'topRight' &&
    css`
      bottom: 100%;
      right: 5%;
      padding-bottom: 7px;
      transform: translateX(4%);
    `}

  ${({ placement }) =>
    placement === 'bottom' &&
    css`
      top: 100%;
      left: 50%;
      padding-top: 6px;
      transform: translateX(-50%);
    `}

  ${({ placement }) =>
    placement === 'bottomLeft' &&
    css`
      top: 100%;
      left: 4%;
      padding-top: 6px;
      transform: translateX(-4%);
    `}

  ${({ placement }) =>
    placement === 'bottomRight' &&
    css`
      top: 100%;
      right: 5%;
      padding-top: 6px;
      transform: translateX(4%);
    `}

  ${({ placement }) =>
    placement === 'left' &&
    css`
      top: 50%;
      right: 100%;
      padding-right: 7px;
      transform: translateY(-50%);
      margin-right: ${({ theme }) => theme.space.xxs};

      ${TooltipMessage} {
        max-width: 270px;

        ${TooltipMessageXsCSS}
      }
    `}

  ${({ placement }) =>
    placement === 'leftTop' &&
    css`
      top: 50%;
      right: 100%;
      padding-right: 7px;
      transform: translateY(-25%);

      ${TooltipMessage} {
        max-width: 270px;

        ${TooltipMessageXsCSS}
      }
    `}

  ${({ placement }) =>
    placement === 'leftBottom' &&
    css`
      bottom: -50%;
      right: 100%;
      padding-right: 7px;
      transform: translateY(-25%);

      ${TooltipMessage} {
        max-width: 270px;

        ${TooltipMessageXsCSS}
      }
    `}

  ${({ placement }) =>
    placement === 'right' &&
    css`
      top: 50%;
      left: 100%;
      padding-left: 7px;
      transform: translateY(-50%);
    `}

  ${({ placement }) =>
    placement === 'rightTop' &&
    css`
      top: 10%;
      left: 100%;
      padding-left: 7px;
      transform: translateY(-10%);
    `}

  ${({ placement }) =>
    placement === 'rightBottom' &&
    css`
      top: 0%;
      left: 100%;
      padding-left: 7px;
      transform: translateY(-50%);
    `}
`

export const TooltipTrigger = styled.span<TooltipBoxProps>`
  display: inline-block;
  text-decoration: underline;
`

export const Wrapper = styled.span`
  position: relative;
`
