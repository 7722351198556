import Spinner from '@berlitz/spinner'
import * as React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'

import { ButtonProps } from './interface'
import { ChildrenBox, IconBox, LoadingText, SpinnerBox, StyledButton } from './style'

const Button: React.FC<ButtonProps & InjectedIntlProps> = (props) => {
  const {
    children,
    icon,
    iconPosition = 'right',
    loading,
    loadingText = props.intl.formatMessage({ id: 'Loading' }),
    ...rest
  } = props

  return (
    <StyledButton {...rest} loading={loading} hasIcon={icon !== undefined} hasChildren={children !== undefined}>
      {!loading && children && (
        <ChildrenBox icon={icon} hasChildren={true} iconPosition={iconPosition}>
          {children}
        </ChildrenBox>
      )}
      {!loading && icon && (
        <IconBox icon={icon} hasChildren={children !== undefined} iconPosition={iconPosition}>
          {icon}
        </IconBox>
      )}
      {loading && (
        <>
          {loadingText && <LoadingText>{loadingText}</LoadingText>}
          <SpinnerBox>
            <Spinner size="sm" />
          </SpinnerBox>
        </>
      )}
    </StyledButton>
  )
}

export default injectIntl(Button)
