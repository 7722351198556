import Button from '@berlitz/button'
import styled, { css } from 'styled-components'
import { ButtonProps, Color } from './interface'

export const SpinnerBox = styled.div``

export const LoadingText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-right: ${({ theme }) => theme.space.sm};
`

export const IconBox = styled.div<ButtonProps>`
  span {
    svg {
      margin-top: ${({ theme }) => theme.space.xxxs};
    }
  }

  ${({ hasChildren, icon, iconPosition }) =>
    hasChildren &&
    icon &&
    iconPosition === 'left' &&
    css`
      span {
        svg {
          margin-right: ${({ theme }) => theme.space.xxs};
        }
      }
    `}

  ${({ hasChildren, icon, iconPosition }) =>
    hasChildren &&
    icon &&
    iconPosition === 'right' &&
    css`
      span {
        svg {
          margin-left: ${({ theme }) => theme.space.xxs};
        }
      }
    `}
`

export const ChildrenBox = styled.div<ButtonProps>`
  vertical-align: middle;

  ${({ icon, iconPosition }) =>
    icon &&
    iconPosition === 'left' &&
    css`
      order: 1;
    `}
`

const getButtonColor = (color: Color, withHover) => {
  const isDefault = color === 'default'
  const defaultSecondary = 'gray20'

  return css`
    background-color: ${({ theme }) => theme.palette[isDefault ? 'white' : `${color}100`]};
    ${(color === 'green' || color === 'default') &&
    css`
      color: ${({ theme }) => (isDefault ? theme.palette.charcoal : theme.colors.brandSecondaryContrast)};
    `}

    ${color === 'blue20' &&
    css`
      color: ${({ theme }) => theme.palette.charcoal};

      ${!withHover &&
      css`
        background-color: ${({ theme }) => theme.palette.blue20};
        &:hover,
        &:active,
        &:focus {
          background-color: ${({ theme }) => theme.palette.blue20};
        }
      `}
    `}

    ${!withHover &&
    css`
      transition: none;
      cursor: default;
    `}

    &:hover {
      background-color: ${({ theme }) =>
        theme.palette[isDefault ? defaultSecondary : `${color}${withHover ? '80' : '100'}`]};
    }

    &:active {
      background-color: ${({ theme }) => theme.palette[isDefault ? defaultSecondary : `${color}100`]};
    }

    &:focus {
      background-color: ${({ theme }) => theme.palette[isDefault ? defaultSecondary : `${color}100`]};
    }
  `
}

const getGhostButtonColor = (color: Color) => css`
  border-color: ${({ theme }) => theme.palette[`${color}100`]};
  color: ${({ theme }) => theme.palette[`${color}100`]};

  &:hover {
    background-color: ${({ theme }) => theme.palette[`${color}10`]};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette[`${color}10`]};
  }

  &:focus {
    background-color: ${({ theme }) => theme.palette[`${color}10`]};
  }
`

export const StyledButton = styled(Button)`
  border: none;

  ${({ color, onClick }: ButtonProps) => color === 'success' && getButtonColor('green', !!onClick)}
  ${({ color, onClick }: ButtonProps) => color === 'warning' && getButtonColor(color, !!onClick)}
  ${({ color, onClick }: ButtonProps) => color === 'green' && getButtonColor(color, !!onClick)}
  ${({ color, onClick }: ButtonProps) => color === 'purple' && getButtonColor(color, !!onClick)}
  ${({ color, onClick }: ButtonProps) => color === 'info' && getButtonColor(color, !!onClick)}
  ${({ color, onClick }: ButtonProps) => color === 'error' && getButtonColor('danger', !!onClick)}
  ${({ color, onClick }: ButtonProps) => color === 'default' && getButtonColor('default', !!onClick)}
  ${({ color, onClick }: ButtonProps) => color === 'blue20' && getButtonColor(color, !!onClick)}

  ${({ disabled }: ButtonProps) =>
    disabled &&
    css`
      background-color: #f0f0f0;
      color: ${({ theme }) => theme.palette.gray100};

      &:hover {
        background-color: #f0f0f0;
      }

      &:focus {
        background-color: #f0f0f0;
      }

      &:active {
        background-color: #f0f0f0;
      }

      svg {
        color: ${({ theme }) => theme.palette.gray100};
      }
    `}

  ${({ narrow, hasChildren, hasIcon }: ButtonProps) =>
    narrow &&
    css`
      padding-top: ${({ theme }) => theme.space.xs};
      padding-bottom: ${({ theme }) => theme.space.xs};

      ${hasIcon &&
      !hasChildren &&
      css`
        padding: ${({ theme }) => `${theme.space.xxxs} ${theme.space.xs}`};
      `}
    `}

  ${({ ghost, narrow, loading, loadingText, color, disabled }: ButtonProps) =>
    ghost &&
    css`
      margin: 0;
      border: 2px solid ${({ theme }) => theme.palette.blue100};
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      color: ${({ theme }) => theme.colors.brandPrimary};
      background-color: transparent;

      &:hover {
        background: rgba(30, 144, 255, 0.15);
      }

      &:active {
        background: rgba(30, 144, 255, 0.15);
      }

      &:focus {
        background: rgba(30, 144, 255, 0.15);
      }

      ${narrow &&
      css`
        padding-top: 6px;
        padding-bottom: 6px;

        ${loading &&
        !loadingText &&
        css`
          padding-top: ${({ theme }) => theme.space.xxs};
          padding-bottom: 2px;
          padding-right: 8px;
          padding-left: 8px;

          ${SpinnerBox} {
            span {
              margin: 0;
            }
          }
        `}
      `}

      ${color === 'success' && getGhostButtonColor(color)}
      ${color === 'warning' && getGhostButtonColor(color)}
      ${color === 'error' && getGhostButtonColor('danger')}

      ${disabled &&
      css`
        background-color: ${({ theme }) => theme.palette.gray20};
        border-color: ${({ theme }) => theme.palette.gray100};
        color: ${({ theme }) => theme.palette.gray100};
      `}
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: 100%;
      padding-left: ${({ theme }) => theme.space.md};
      padding-right: ${({ theme }) => theme.space.md};
    `}
`
