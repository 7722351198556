import * as React from 'react'

import { TooltipProps, TooltipState } from './interface'
import { TooltipBubble, TooltipMessage, TooltipTrigger, Wrapper } from './style'

class Tooltip extends React.Component<TooltipProps, TooltipState> {
  static defaultProps = {
    placement: 'top',
  }

  state: TooltipState = {
    displayTooltip: false,
  }

  hideTooltip = () => {
    this.setState({ displayTooltip: false })
  }

  showTooltip = () => {
    this.setState({ displayTooltip: true })
  }

  render() {
    const { children, message, placement, visible } = this.props
    const { displayTooltip } = this.state

    return (
      <Wrapper onMouseLeave={this.hideTooltip}>
        {(displayTooltip || visible) && (
          <TooltipBubble placement={placement}>
            <TooltipMessage>{message}</TooltipMessage>
          </TooltipBubble>
        )}
        <TooltipTrigger onMouseOver={this.showTooltip}>{children}</TooltipTrigger>
      </Wrapper>
    )
  }
}

export default Tooltip
